<template>
  <viewcard--c
    title="Visualizar Gravação"
    :btsave="null"
    :btdelete="null"
    :btback="{}"
    :busy="!loading"
  >
    <Tracking
      :id="{ recording_media_id: recordingID }"
      class="tracking"
      size="30px"
    />
    <hr class="p-0 m-0 mb-1" />
    <RecordingInfo @mounted="loadingInfo = true" />
    <hr class="p-0 m-0 mb-1 mt-1" ref="container" />
    <RecordingView @mounted="loadingView = true" />
  </viewcard--c>
</template>
<script>
import RecordingInfo from "./RecordingInfo.vue";
import RecordingView from "./RecordingView.vue";
import Tracking from "@/components/Tracking.vue";

export default {
  components: {
    RecordingInfo,
    RecordingView,
    Tracking,
  },
  computed: {
    loading() {
      return this.loadingInfo && this.loadingView;
    },
  },
  data() {
    return {
      recordingID: this.$route.params.id,
      loadingInfo: false,
      loadingView: false,
    };
  },
};
</script>
<style scoped>
.tracking {
  position: absolute !important;
  top: 25px;
  left: 220px;
}
</style>